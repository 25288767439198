<template>
    <div>
        <button @click="toggleWidget"
            class="fixed bottom-24 right-5 bg-purple-500 text-white rounded-full p-4 shadow-lg hover:bg-purple-600 transition duration-300 flex items-center justify-center w-16 h-16"
            title="Atendimento">
            <i class="fas fa-comment-alt text-2xl"></i>
        </button>

        <div v-if="showWidget" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div class="bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-4xl h-[80%] relative">
                <button @click="toggleWidget" class="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-xl">
                    ✖
                </button>
                <iframe id="widget-iframe" src="https://platform.zaia.app/embed/chat/36857"
                    class="w-full h-full rounded-lg border border-gray-200" style="border: none;"></iframe>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const showWidget = ref(false);

function toggleWidget() {
    showWidget.value = !showWidget.value;
}
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>