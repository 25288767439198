<template>
  <div class="flex flex-col min-h-screen">
    <header>
      <Header />
    </header>
    <footer>
      <Footer />
    </footer>
    <WhatsappLink />
    <AgentButton />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from "@/components/Footer.vue";
import WhatsappLink from '@/components/Buttons/WhatsappLink.vue';
import AgentButton from '@/components/Buttons/AgentButton.vue';

export default {
  name: 'HomePage',
  components: {
    Footer,
    Header,
    WhatsappLink,
    AgentButton
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
